
import { ApiControllers } from "./config";
import { walletsApi, ipRegistry, walletsgridClient, ipStackClient, apiClient,web3Client, uploadClient } from './clients'
import CryptoJS from "crypto-js";
import { arcanaAuth } from "../auth/web3Config";
const environment = process.env.REACT_APP_WALLET_TYPE;
const get = (url) =>{
    if(environment === 'non_custodial'){
        return web3Client.get(`${url}`);
    }else{
        return apiClient.get(`${url}`);
    }
}
const getMerchantLU = (cutomerId) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Merchant/GetMerchantDetails/${cutomerId}`);
    }else{
        return apiClient.get(`Merchant/GetMerchantDetails/${cutomerId}`);
    }
}

const getMember = () => {
    return walletsApi.get(ApiControllers.registration + `App/Wallets`);
};
const getIpRegistery = () => {
    return ipRegistry.get("/?key=q329a11cly03mmf2");
};

const twofactor = (custId) => {
    return walletsApi.get(ApiControllers.customers + `twofactor/${custId}`);
};

const getOrderHistoryGrid = (apiMethod, id, pageNo, pageSize) => {
    return walletsApi.get(`${apiMethod}/${id}?page=${pageNo}&pageSize=${pageSize}`)
}
const searchTransactions = (type) => {
    return walletsgridClient.get(ApiControllers.transaction + `Customers/${type}`)
}

const encryptValue = (msg, key) => {
    msg = typeof msg == "string" ? msg : JSON.stringify(msg);
    let salt = CryptoJS.lib.WordArray.random(128 / 8);

    let key1 = CryptoJS.PBKDF2(key, salt, {
        keySize: 256 / 32,
        iterations: 10,
    });

    let iv = CryptoJS.lib.WordArray.random(128 / 8);

    let encrypted = CryptoJS.AES.encrypt(msg, key1, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });
    return salt.toString() + iv.toString() + encrypted.toString();
};
const getIpStock = () => {
    return ipStackClient.get('check?access_key=f16009057980d892d3b078963e5c51af')
}
const sumsubacesstoken = (userid, flow) => {
    return apiClient.get(
        "Kyc/AccessToken1?applicantId=" + userid + "&levelName=" + flow
    );
};
const handleWeb3User = ({ address, loginToken }) => {
    if (loginToken) {
        return web3Client.get(ApiControllers.customers + "customerCreation/" + address, {}, {
            headers: {
                authorization: loginToken
            }
        })
    }
}

const getArcanaChains = () => {
    return fetch(`${arcanaAuth?.networkConfig?.gatewayUrl}/api/v1/chains/${process.env.REACT_APP_ARCANA_ID}`)
}

const cryptoPaymentsGridDeatials=(id,pageNo,pageSize)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.merchant + `CryptoBatchPaymentsK/${id}?page=${pageNo}&pageSize=${pageSize}`);
    }else{
        return apiClient.get(ApiControllers.merchant + `CryptoBatchPaymentsK/${id}?page=${pageNo}&pageSize=${pageSize}`);
    }



}
const batchPayoutDelete=(id,batchid)=>{
    if(environment === 'non_custodial'){
        return web3Client.delete(ApiControllers.merchant+`deletebatchpayments/${id}/${batchid}`)
    }else{
        return apiClient.delete(ApiControllers.merchant+`deletebatchpayments/${id}/${batchid}`)
    }
}
const fetchBatchPayoutDetails=(id,batchid)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.merchant+`batchpaymentview/${id}/${batchid}`)
    }else{
        return apiClient.get(ApiControllers.merchant+`batchpaymentview/${id}/${batchid}`)
    }
}

const cryptoAmtVerification=(customerId,currency,amount,networkId,network)=>{
    return apiClient.get(ApiControllers.exchangeTransaction + `Verifications/${customerId}/${currency}/${amount}/${networkId}/${network}`)
}

const uploadFile=(file)=>{
    return uploadClient.post(`uploadFile`,file)
}
const sumsublivenessacesstoken = (userid, flow) => {
	return apiClient.get(
		"Kyc/ExternalAccessToken?userId=" + userid + "&levelName=" + flow
	);
};
const apicalls = {
    get,
    getMember,
    handleWeb3User,
    getArcanaChains,
    getIpRegistery,
    twofactor,uploadFile, getOrderHistoryGrid, searchTransactions, encryptValue, getIpStock, sumsubacesstoken,cryptoPaymentsGridDeatials,batchPayoutDelete,fetchBatchPayoutDetails,
    cryptoAmtVerification,sumsublivenessacesstoken,getMerchantLU
};
export default apicalls;
