export const ApiControllers = {
    withdraw: "Withdraw/",
    buySell: "Buysell/",
    master: "Master/",
    addressbook: "addressbook/",
    deposit: "Deposit/",
    customers: "Customer/",
    markets: "Markets/",
    wallets: "Wallets/",
    dashboard: "Dashboard/",
    partner:"Partner/",
    custodian:"Custodian/",
    exchange:"Exchange/",
    address:"Address/",
    common :"Common/",
    transaction:"Transaction/",
    transactions:"Transactions/",
    registration:'Registration/',
    exchangewallet:"ExchangeWallet/",
    exchangeTransaction:"ExchangeTransaction/",
    security:"Security/",
    exchangetransaction:"ExchangeTransaction/",
    notification:"Notification/",
    merchant:"Merchant/",
}