import {publishTransactionRefresh} from '../utils/pubsub'
const SET_ACCOUNTDETALS = "setAccountDetails"
const SET_NOTIF_COUNT = "setNotificationCount";


const setAccountDetails = (payload) => {
    return {
        type: SET_ACCOUNTDETALS,
        payload
    }
}

const setNotificationCount = (payload) => {
    return {
        type: SET_NOTIF_COUNT,
        payload
    }
}
const fetchDashboardcalls = () => {
   return async () => {
    publishTransactionRefresh();
} 
  }

let initialState = {
    accountDetails: [],
    notificationCount: 0,

}


const dashboardReducer = (state, action) => {
    if(!state){
        state={...initialState,...state}
      }
    switch (action.type) {

        case SET_ACCOUNTDETALS:
            return { ...state, accountDetails: action.payload };
        case SET_NOTIF_COUNT:
            let _count = action.payload
            if (action?.payload < 0) {
                _count = 0;
            }
            state = { ...state, notificationCount: _count }
            return state;
        default:

            return state;
    }
}
export default dashboardReducer;
export { setAccountDetails, setNotificationCount,fetchDashboardcalls }
