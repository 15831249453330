
import apiCalls from "../api/apiCalls";
import { deriveErrorMessage } from "../utils/deriveErrorMessage";
const USEER_INFO = "userInfo";
const UPDATE_DOC_REQUEST = "updateDocRequest";
const FETCH_TRACK_AUDITLOGS = "fetchtrackauditlogs";
const CLEAR_USER_INFO = "clearUserInfo";
const UPDATE_TWOFACTOR = "updatetwofactor";
const SET_WEB3_USER = "setWeb3User";
const SET_GETTING_USER_INFO = "setGettingUserInfo"
const SET_ARCANA_CHAINS = 'setArcanaChains'
const userInfo = (payload) => {
    return {
        type: USEER_INFO,
        payload
    }
};
const fetchtrackauditlogs = (payload) => {
    return {
        type: FETCH_TRACK_AUDITLOGS,
        payload
    }
};
const updateDocRequest = (payload) => {
    return {
        type: UPDATE_DOC_REQUEST,
        payload
    }
};
const updatetwofactor = (payload) => {
    return {
        type: UPDATE_TWOFACTOR,
        payload
    }
};
const clearUserInfo = () => {
    return { type: CLEAR_USER_INFO, payload: null }
}
const setGettingUserInfo = (payload) => {
    return {
        type: SET_GETTING_USER_INFO,
        payload
    }
}
const setWeb3User = (payload) => {
    return {
        type: SET_WEB3_USER,
        payload
    }
};
const setArcanaChains = (payload) => {
    return {
        type: SET_ARCANA_CHAINS,
        payload
    }
}
const getArcanaSupportedChains = () => {
    return async (dispatch) => {
        dispatch(
            setArcanaChains({
                loader: true,
                data: null,
                error: "",
            })
        );
        try {
            const response = await apiCalls.getArcanaChains();
            const finalRes = await response.json()
            if (response.ok) {
                dispatch(
                    setArcanaChains({
                        loader: false,
                        data: finalRes?.chains,
                        error: "",
                    })
                );
            } else {
                dispatch(
                    setArcanaChains({
                        loader: false,
                        data: null,
                        error: deriveErrorMessage(finalRes),
                    })
                );
            }
        } catch (error) {
            dispatch(
                setArcanaChains({
                    loader: false,
                    data: null,
                    error: deriveErrorMessage(error),
                })
            );
        }
    };
}
const handleWeb3Login = (payload, successCallback, failureCallback) => {
    return async (dispatch) => {
        apiCalls.handleWeb3User(payload).then((user) => {
            if (user.ok) {
                dispatch(userInfo(user.data));
                successCallback?.();
            } else {
                dispatch(userInfo(null));
                failureCallback?.()
            }
        }).catch(() => {
            dispatch(userInfo(null));
            failureCallback?.()
        })
    }
}
const getmemeberInfo = () => {
    return async (dispatch) => {
        let twofa;

        apiCalls.getMember().then((res) => {
            if (res.ok) {
                res.data.twofactorVerified = twofa;
                dispatch(userInfo(res.data));

            }
        });
    }
}

const getIpRegisteryData = () => {
    return async (dispatch) => {
        apiCalls.getIpRegistery().then((res) => {
            if (res.ok) {
                let ipInfo = {
                    "Ip": res.data.ip,
                    "Location": {
                        "countryName": res.data.location.country.name,
                        "state": res.data.location.region.name.replace(/ā/g, 'a'),
                        "city": res.data.location.city,
                        "postal": res.data.location.postal,
                        "latitude": res.data.location.latitude,
                        "longitude": res.data.location.longitude
                    },
                    "Browser": res.data.user_agent.name,
                    "DeviceType": {
                        "name": res.data.user_agent.device.name,
                        "type": res.data.user_agent.os.type,
                        "version": res.data.user_agent.os.name + ' ' + res.data.user_agent.os.version
                    }
                }
                dispatch(fetchtrackauditlogs(ipInfo));
            }
        });
    }
}

let initialState = {
    userProfileInfo: null,
    trackAuditLogData: {},
    twoFA: { loading: true, isEnabled: false },
    gettingUserInfo: '',
    web3User: null,
    arcanaChains: { loading: false, data: [], error: '' },
};
const UserConfig = (state, action) => {
    if (!state) {
        state = { ...initialState, ...state }
    }
    switch (action.type) {
        case USEER_INFO:
            state = { ...state, userProfileInfo: action.payload }
            return state;
        case UPDATE_DOC_REQUEST:
            state = { ...state, userProfileInfo: { ...state.userProfileInfo, isDocsRequested: action.payload } }
            return state;
        case UPDATE_TWOFACTOR:
            if (typeof action.payload == "boolean")
                state = { ...state, userProfileInfo: { ...state.userProfileInfo, twofactorVerified: action.payload } }
            else
                state = { ...state, twoFA: { loading: action.payload.loading, isEnabled: action.payload.isEnabled } }
            return state;
        case FETCH_TRACK_AUDITLOGS:
            state = { ...state, trackAuditLogData: action.payload }
            return state;
        case CLEAR_USER_INFO:
            state = { userProfileInfo: null, trackAuditLogData: {} };
            return state;
        case SET_WEB3_USER:
            state = { ...state, web3User: action.payload };
            return state;
        case SET_GETTING_USER_INFO:
            state = { ...state, gettingUserInfo: action.payload }
            return state;
        case SET_ARCANA_CHAINS:
            state = { ...state, arcanaChains: action.payload }
            return state;
        default:
            return state;
    }
}

export default UserConfig;
export { userInfo, getmemeberInfo, updateDocRequest, getIpRegisteryData, fetchtrackauditlogs, clearUserInfo, updatetwofactor,setGettingUserInfo,getArcanaSupportedChains,setWeb3User,handleWeb3Login };