import { apiClient,web3Client } from "../../api/clients";
import { ApiControllers } from "../../api/config";
const environment = process.env.REACT_APP_WALLET_TYPE;

const getFiatPayeeLu = (customerId,currency) => {
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.exchangewallet + `Exchange/PayeeLu/${customerId}/${currency}`);
    }else{
        return apiClient.get(ApiControllers.exchangewallet + `Exchange/PayeeLu/${customerId}/${currency}`);
    }
};
const getPayeeCryptoLu = (customerId,currency,network) => {
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.common + `PayeeCryptoLU/${customerId}/${currency}/${network}`);
    }else{
        return apiClient.get(ApiControllers.common + `PayeeCryptoLU/${customerId}/${currency}/${network}`);
    }
};
const saveCryptoWithdraw = (obj) => {
    if(environment === 'non_custodial'){
        return web3Client.post(`ExchangeTransaction/PaymentWithdraw/Crypto`, obj);
    }else{
        return apiClient.post(`ExchangeTransaction/PaymentWithdraw/Crypto`, obj);
    }
}
const getWithdrawCustomerBanks=(walletcode)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.exchangewallet+`Exchange/WithdrawFiat/CustomerBanks/${walletcode}`);
    }else{
        return apiClient.get(ApiControllers.exchangewallet+`Exchange/WithdrawFiat/CustomerBanks/${walletcode}`);
    }
}

const confirmTransaction = (obj) => {
    if(environment === 'non_custodial'){
        return web3Client.post(ApiControllers.exchangewallet + `Exchange/Withdraw/Fiat/Summary`, obj);
    }else{
        return apiClient.post(ApiControllers.exchangewallet + `Exchange/Withdraw/Fiat/Summary`, obj);
    }
}

const getFiatSummarySave = (obj) =>{
    if(environment === 'non_custodial'){
        return web3Client.post(`ExchangeTransaction/PaymentWithdraw/Fiat`,obj)
    }else{
        return apiClient.post(`ExchangeTransaction/PaymentWithdraw/Fiat`,obj)
    }
}

const getWithdrawmemberCrypto = (customerId) => {
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.exchangewallet + `Exchange/CryptoWallets/${customerId}`)
    }else{
        return apiClient.get(ApiControllers.exchangewallet + `Exchange/CryptoWallets/${customerId}`)
    }
}
const getNetworkLu = (customerId,currency) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Common/Wallets/NetWorkLU/${currency}/${customerId}`);
    }else{
        return apiClient.get(`Common/Wallets/NetWorkLU/${currency}/${customerId}`);
    }
}


//https://devdigitalweb3payments.azurewebsites.net/api/v1/Merchant/merchantbalance/{merchantId}/{currency}

const getMerchantBalance = (merchantId,currency) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Merchant/merchantbalance/${merchantId}/${currency}`);
    }else{
        return apiClient.get(`Merchant/merchantbalance/${merchantId}/${currency}`);
    }
}

//https://devdigitalweb3payments.azurewebsites.net/api/v1/Merchant/merchantpaymentsummary/{coin}/{currency}/{amount}/{merchantId}

const getFiatSummary = (coin,currency,amount,merchantId)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.exchangewallet+`Exchange/Withdraw/Fiat/Summary`);
    }else{
        return apiClient.get(ApiControllers.exchangewallet+`Exchange/Withdraw/Fiat/Summary`);
    }
}
const saveTransaction = (obj,screenType) => {
    // if(screenType == 'fiat'){
        if(environment === 'non_custodial'){
            return web3Client.post(ApiControllers.exchangewallet + `PaymentCryptoToFiat/Confirm`, obj);
        }else{
            return apiClient.post(ApiControllers.exchangewallet + `PaymentCryptoToFiat/Confirm`, obj);
        }
    // }
    // else
    //     if(environment === 'non_custodial'){
    //         return web3Client.post(ApiControllers.exchangewallet + `Crypto/Confirm`, obj);
    //     }else{
    //         return apiClient.post(ApiControllers.exchangewallet + `Crypto/Confirm`, obj);
    //     }    
}


const getPayee = (customerId,currency,network)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(`Common/PayeeCryptoLU/${customerId}/${currency}/${network}`);
    }else{
        return apiClient.get(`Common/PayeeCryptoLU/${customerId}/${currency}/${network}`);
    }
}
const getVerification = (customerId,code) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Security/OTPVerification/${customerId}/${code}`);
    }else{
        return apiClient.get(`Security/OTPVerification/${customerId}/${code}`);
    }
};
const getVerificationFields = (customerId) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Security/Verificationfields/${customerId}`);
    }else{
        return apiClient.get(`Security/Verificationfields/${customerId}`);
    }
};
const getCode = (customerId,isResendOTP) => {
    if(environment === 'non_custodial'){
        return web3Client.get(`Security/SendOTP/${customerId}/${isResendOTP}`);
    }else{
        return apiClient.get(`Security/SendOTP/${customerId}/${isResendOTP}`);
    }
};
const sellCryptoFiatConverter=(cryptoCoins,fiatCurency,value,flag,userId)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.common + `PaymentCryptoFiatConverter/${userId}/${cryptoCoins}/${fiatCurency}/${value}/${flag}/BatchPayout`);
    }else{
        return apiClient.get(ApiControllers.common + `PaymentCryptoFiatConverter/${userId}/${cryptoCoins}/${fiatCurency}/${value}/${flag}/BatchPayout`);
    }
}
const sellSaveObj = (fromCoin,toCoin,fromAmount,userId,flag)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.buySell + `Sell/Coins/${fromCoin}/${toCoin}/${fromAmount}/${flag}/${userId}`)
    }else{
        return apiClient.get(ApiControllers.buySell + `Sell/Coins/${fromCoin}/${toCoin}/${fromAmount}/${flag}/${userId}`)
    }
}
const fetchFiatCoins = (id) => {
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`);
    }else{
        return apiClient.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`);
    }
}
export { getFiatPayeeLu, confirmTransaction,getFiatSummarySave,
          getCode,getVerification,getWithdrawmemberCrypto,
          getPayeeCryptoLu,saveTransaction,saveCryptoWithdraw,
          getWithdrawCustomerBanks,getNetworkLu,
          getMerchantBalance , getFiatSummary , getPayee,getVerificationFields,
          sellCryptoFiatConverter,sellSaveObj,fetchFiatCoins};