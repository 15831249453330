import { deriveErrorMessage } from "../../utils/deriveErrorMessage"
import { get, getDetais,save, update } from "./api"


export const actionStatusList={
    'update':['paid','cancelled','processed',"processing"],
    'stateChange':['processed','cancelled'],
}
export const statusWarningTexts=(action)=>{
    return {
        'partially paid':'Batch payout has been partially paid.',
        'paid':'Batch payout has been fully paid.',
        'cancelled':`Cannot ${action} cancelled Batch payout`,
        'processed':'Batch payout has been processed you can not change the state.',
    }
}
export const toolbar=[
    {key:'add',tooltipTitle:'Create',tooltipPlacement:'top',icon:'add-links',shouldSelect:false},
    {key:'update',tooltipTitle:'Update',tooltipPlacement:'top',icon:'Edit-links',shouldSelect:true},
    {key:'stateChange',tooltipTitle:'Change Status',tooltipPlacement:'top',icon:'statechange-icon',shouldSelect:true},
]

export const getStatusChangeLookup=async (fromStatus)=>{
    const response = await get(`Common/StateChange/Batch-PayOut/${fromStatus}`);
    if (response.status === 200) {
        return response.data;
    }
    else {
        throw new Error(deriveErrorMessage(response));
    }
}

export const updatePayoutStatus=async (selectedPayin,status,customer)=>{
    const obj={
        status,
        modifiedBy:customer?.userName || customer?.firstName,
        modifiedDate:new Date().toISOString()
    }
    try {
        const response = await update(`Merchant/BatchPayOut/StateChange/${selectedPayin?.id}`,obj);
        if (response.ok) {
            return {
                data: true, error: ''
            }
        }
        return { data: null, error: deriveErrorMessage(response.data) }
    }
    catch (error) {
        return { data: null, error: deriveErrorMessage(error) }
    }
}
export const getLooups = async (url, customerId) => {
    try {
        let response = await getDetais(url, customerId);
        if (response.status === 200) {
            return { success: true, data: response.data };
        }
        else {
            return { success: false, error: response };
        }
    }
    catch (error) {
        return error
    }
}
export const getSampleFile = async (url) => {
    try {
        let response = await get(url);
        if (response.status === 200) {
            return response.data
        }
        else{
            return response
        }
    }
    catch (error) {
        return error
    }

}

export const saveBatchPayment = async (url, values, customerId, id,mode) => {
    try {
        let obj = {
            ...values,
            id: id,
            customerId: customerId,
        }
        let _url = mode === 'create' ? url : 'Merchant/updatebatchpayments'
        let _methods = mode === 'create' ? save : update;
        let response = await _methods(_url, obj);
        return response;
    }
    catch (error) {
        return error
    }

}