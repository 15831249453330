
import { apiClient, web3Client } from '../../api/clients'
const environment = process.env.REACT_APP_WALLET_TYPE;
const client = {
    'non_custodial': web3Client,
    'custodial': apiClient
}
const getPayeesGrid = (id, type, pageNo, pageSize, search) => {
    let url=`Common/payees/${id}/${type}/${pageSize}/${pageNo}/${search}`
    url=type==='fiat' && environment !== 'non_custodial' ? `${url}/${process.env.REACT_APP_NAME}` : `${url}/null`
    if (environment === 'non_custodial') {
        return web3Client.get(url);
    } else {
        return apiClient.get(url);
    }
}
const getPayeeInfo = (type, id) => {
    let url=`Common/Payee/${type}/${id}`
    url=type==='fiat' && environment !== 'non_custodial' ? `${url}/${process.env.REACT_APP_NAME}`:`${url}`
    if (environment === 'non_custodial') {
        return web3Client.get(url);
    } else {
        return apiClient.get(url);
    }
}
const getPayeeCryptoCoins = (id) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/Coins/${id}`);
    } else {
        return apiClient.get(`Common/Coins/${id}`);
    }
}
const getCryptoData = (id) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/Payee/Crypto/${id}`);
    } else {
        return apiClient.get(`Common/Payee/Crypto/${id}`);
    }
};
const getNetworks = (coin) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/Wallets/NetworkLu/${coin}`);
    } else {
        return apiClient.get(`Common/Wallets/NetworkLu/${coin}`);
    }
}
const WalletAdressVerification = (obj) => {
    if (environment === 'non_custodial') {
        return web3Client.post(`verifywalletaddress`, obj);
    } else {
        return apiClient.post(`verifywalletaddress`, obj);
    }
}
const ProofTypeLU = () => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/ProofTypeLookUp`);
    } else {
        return apiClient.get(`Common/ProofTypeLookUp`);
    }
}

const getWalletSources = (type) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/WalletSources/${type}`);
    } else {
        return apiClient.get(`Common/WalletSources/${type}`);
    }
}
const saveCryptoPayee = (obj, method) => {
    return client[environment][method](`Common/Payee/Crypto`, obj);
};

const activeInactive = (obj) => {
    if (environment === 'non_custodial') {
        return web3Client.put(`Common/useractiveinactive`, obj);
    } else {
        return apiClient.put(`Common/useractiveinactive`, obj);
    }
};
const getFiatCoins = (id) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`ExchangeWallet/Exchange/FiatWallets/${id}`);
    } else {
        return apiClient.get(`ExchangeWallet/Exchange/FiatWallets/${id}`);
    }
}
const saveFiatPayee = (obj, method) => {
    return client[environment][method](`Common/Payments/Payee/Fiat`, obj);
}
const getCountryStateLu = () => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/States`);
    } else {
        return apiClient.get(`Common/States`);
    }
}
const getIBANData = (ibannumber) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`ExchangeWallet/GetIBANAccountDetails?ibanNumber=` + ibannumber);
    } else {
        return apiClient.get(`ExchangeWallet/GetIBANAccountDetails?ibanNumber=` + ibannumber);
    }
};

const fetchPaymentFields = (type) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`DashBoard/PaymentFields/${type}`);
    } else {
        return apiClient.get(`DashBoard/PaymentFields/${type}`);
    }
}
const fetchSatoshiDetails = (_network, _walletAddress, customerId) => {
    if (environment === 'non_custodial') {
        return web3Client.get(`Common/sathositest/` + `${_network}/${_walletAddress}/${customerId}`);
    } else {
        return apiClient.get(`Common/sathositest/` + `${_network}/${_walletAddress}/${customerId}`);
    }
}
const saveSatoshiTest=(obj)=>{
    if (environment === 'non_custodial') {
        return web3Client.post(`Common/sathositestdeposit`,obj);
    } else {
        return apiClient.post(`Common/sathositestdeposit`,obj);
    }
}
const saveSelfSignature=(obj)=>{
    if (environment === 'non_custodial') {
        return web3Client.post(`Common/SaveSelfSignedDeposit`,obj);
    } else {
        return apiClient.post(`Common/SaveSelfSignedDeposit`,obj);
    }
}
export {fetchSatoshiDetails,saveSatoshiTest, saveSelfSignature,getIBANData, fetchPaymentFields, getCountryStateLu, saveFiatPayee, getFiatCoins, activeInactive, getWalletSources, ProofTypeLU, getPayeesGrid, getPayeeInfo, getPayeeCryptoCoins, getCryptoData, getNetworks, WalletAdressVerification, saveCryptoPayee }