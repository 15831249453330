
import { fetchFiatCoins } from "../components/payOut/api";
const GET_CRYPTOREVIEWDETAILS = "getCryptoReviewDetails"
const GET_FIAT_AMOUNTDATA = "getFiatAmountData"
const GET_CRYPTO_AMOUNTDATA = "getCryptoAmountData"
const GET_FIATLISTDATA = 'getFiatListData';
const GET_FIATADDRESSLISTDATA = "getFiatAddressListData";
const GET_CRYPTOADDRESSLISTDATA = "getCryptoAddressListData";
const SET_SELECTED_NETWORK = "setselectedNetwork";
const SETFIATSAVEDATA = 'setFiatSaveData'

const getFiatAddressListData = (payload) => {
    return {
        type: GET_FIATADDRESSLISTDATA,
        payload
    };
}
const getCryptoAddressListData = (payload) => {
    return {
        type: GET_CRYPTOADDRESSLISTDATA,
        payload
    };
}
const getCryptoReviewDetails = (payload) => {
    return {
        type: GET_CRYPTOREVIEWDETAILS,
        payload
    };
}

const getFiatAmountData = (payload) => {
    return {
        type: GET_FIAT_AMOUNTDATA,
        payload
    }
}
const getCryptoAmountData = (payload) => {
    return {
        type: GET_CRYPTO_AMOUNTDATA,
        payload
    }
}
const getFiatListData = (payload) => {
    return {
        type: GET_FIATLISTDATA,
        payload
    }
};

const setselectedNetwork = (error) => ({
    type: SET_SELECTED_NETWORK,
    payload: error,
});

export const setFiatSaveData = (payload) => {
    return {
        type: SETFIATSAVEDATA,
        payload
    };
}

const featchFiatCoinDetails = (customerId, flag) => {
    return async (dispatch) => {
        dispatch(getFiatListData({ key: "fiatListData", loading: true, data: [], error: null }))
        const response = await fetchFiatCoins(customerId);
        if (response.ok) {
            dispatch(getFiatListData({ key: "fiatListData", loading: false, data: response.data, isSummary: flag, error: null }))
        } else {
            dispatch(getFiatListData({ key: "fiatListData", loading: false, data: [], error: response.originalError?.message }))
        }
    }
}

const initialState = {
    fiatReviewDetails: { loading: false, data: {} },
    cryptoReviewDetails: { loading: false, data: {} },
    fiatListData: { loading: false, data: [], error: null },
    fiatCoinAmountDetails: null,
    cryptoCoinAmountDetails: null,
    fiatAddressListData: null,
    cryptoAddressListData: null, selectedNetwork: null,
    fiatSaveData: null,

}
const PayOutReducer = (state, action) => {
    state = state || initialState
    switch (action.type) {
        case GET_CRYPTOREVIEWDETAILS:
            return { ...state, cryptoReviewDetails: action.payload };
        case GET_FIAT_AMOUNTDATA:
            return { ...state, fiatCoinAmountDetails: action.payload };
        case GET_CRYPTO_AMOUNTDATA:
            return { ...state, cryptoCoinAmountDetails: action.payload };
        case GET_FIATLISTDATA:
            return state;
        case GET_FIATADDRESSLISTDATA:
            return { ...state, fiatAddressListData: action.payload };
        case GET_CRYPTOADDRESSLISTDATA:
            return { ...state, cryptoAddressListData: action.payload };
        case SET_SELECTED_NETWORK:
            return { ...state, selectedNetwork: action.payload }
        case SETFIATSAVEDATA:
            return { ...state, fiatSaveData: action.payload }
        default:
            return state;
    }
}
export default PayOutReducer;
export {
    featchFiatCoinDetails, getFiatAmountData, getCryptoAmountData,
    getCryptoReviewDetails, getFiatAddressListData, getCryptoAddressListData, setselectedNetwork
}