import React, { useMemo } from 'react'
import AppText from '../../shared/appText'
const defaults = {
    tooltips: ['Copy', 'Copied'],
    format: 'text/plain',
    suffixChars: 4,
    prefixChars:4,
    type: 'text',
    shouldTruncate: true,
    className: "summary-text m-0",
}
const CopyComponent = ({ componentClass = defaults.className, text, format = defaults.format, tooltips = defaults.tooltips, type = defaults.type, shouldTruncate = defaults.shouldTruncate, suffixChars = defaults.suffixChars,prefixChars=defaults.prefixChars, link }) => {
    const textToDisplay = useMemo(() => {
        return shouldTruncate ? `${text?.substring(0, prefixChars)}...${text?.substring(text.length - suffixChars)}` : text
    }, [text, shouldTruncate, prefixChars,suffixChars])
    if (!text) {
        return <></>
    }
    return (
        <AppText copyable={{ tooltips, format, text }} className={componentClass}>
            {type === 'link' && (
                <a className="text-link c-pointer" href={`${link}`} target="_blank"
                    rel="noopener noreferrer">
                    {textToDisplay}
                </a>
            )}
            {type==='text' && (
                <span>{textToDisplay}</span>
            )}
        </AppText>

    )
}

export default CopyComponent