import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Menu, Layout, Dropdown, Row, Col, Select } from 'antd';
import { withRouter } from "react-router-dom";
import { useDisconnect } from 'wagmi';
import DefaultUser from "../assets/images/defaultuser.jpg";
import { connect, useDispatch, useSelector } from "react-redux";
import { userManager } from '../auth';
import { userLogout } from '../reducers/authReducer';
import { useAuth0 } from '@auth0/auth0-react';
import { clearUserInfo, setGettingUserInfo } from '../reducers/configReduser';
import Notifications from "../components/notifications.component"
import { readNotification } from '../components/notifications.component/api';
import apicalls from '../api/apiCalls';
import { setNotificationCount } from '../reducers/dashboardReducer';
import { checkCustomerState } from '../utils/service';
import { arcanaAuth } from '../auth/web3Config';
import AppButton from '../shared/appButton';
import useSwitchNetwork from '../shared/useSwitchNetwork';
import Loader from '../shared/loader';
import ConnectWeb3 from '../auth/connectWeb3';
import CopyComponent from '../components/shared.component/copyComponent';
const { Text, Title } = Typography;
const { Sider } = Layout;
const { Option } = Select
const WalletType = process.env.REACT_APP_WALLET_TYPE;
if (WalletType === 'non_custodial') {
  window.onload = async function () {
    try {
      await arcanaAuth?.init()
    } catch (error) {
    }
  };
}
const LogoutWeb3App = () => {
  const dispatch = useDispatch()
  const { disconnectAsync } = useDisconnect()
  const { gettingUserInfo } = useSelector(store => store.userConfig)
  const clearEvents = async () => {
    dispatch(setGettingUserInfo('disconnecting'))
    try {
      await disconnectAsync()
    } catch (error) {
      dispatch(setGettingUserInfo(''))
    }
  }
  return (<li className="">
    {gettingUserInfo === 'disconnecting' && <Text className="text-link">Disconnecting....</Text>}
    {!gettingUserInfo && <AppButton className="disconnect-btn" onClick={clearEvents} disabled={gettingUserInfo}>
      <Text className="head-exit">Disconnect</Text></AppButton>}
  </li>)
}
const LogoutApp = (props) => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const clearEvents = () => {
    dispatch(clearUserInfo());
    dispatch(userLogout());
    window.$zoho?.salesiq?.chat.complete();
    window.$zoho?.salesiq?.reset();
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_REDIRECT_URI
      }
    });
  }
  return (
    <li className='head-button c-pointer' onClick={() => { userManager.signoutRedirect() }}>
      <Text className="head-exit" onClick={clearEvents}>
        Logout
      </Text>
    </li>
  );
};
const Network = ({ isOpen }) => {
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const { switching, switchNetwork } = useSwitchNetwork()
  const supportedNetworks = useSelector((store) => store.userConfig.arcanaChains)
  useEffect(() => {
    getNetwork()
  }, [arcanaAuth?.provider?.chainId, supportedNetworks])
  useEffect(() => {
    if (!isOpen) {
      setSelectedNetwork(currentNetwork || null)
    }
  }, [isOpen, currentNetwork])
  const handleNetworkSelection = (value) => {
    setSelectedNetwork(value)
  }
  const getNetwork = () => {
    const id = arcanaAuth?.provider?.chainId;
    const network = supportedNetworks?.data?.find(networkdata => networkdata.chain_id == id)
    setCurrentNetwork(network?.name)
  }
  return <>
    {switching && <div className="network-loader"><Loader /></div>}
    {!switching && <Text className="current-network">{currentNetwork || '--'}</Text>}
    <div className="switch-network">
      <Select loading={switching} value={selectedNetwork || currentNetwork || null} className="change-network" onChange={(e) => handleNetworkSelection(e)} placeholder="Select network">
        {supportedNetworks?.data?.map((network) => {
          return <Option key={network.chain_id} value={network.chain_id}>{network.name}</Option>
        })}
      </Select>
      <AppButton onClick={async () => {
        await switchNetwork(selectedNetwork)
        setSelectedNetwork(null)
      }} className='switch-btn'>Switch</AppButton>
    </div>

  </>
}

const FilterDropdown = ({ isProfileDropdownVisible }) => {
  const [isWeb3] = useState(process.env.REACT_APP_WALLET_TYPE === "non_custodial")
  const { web3User, userProfileInfo } = useSelector((store) => store.userConfig)
  return (
    <div className="profile-dropdown-menu">
      <img
        src={userProfileInfo?.imageURL ? userProfileInfo?.imageURL : DefaultUser}
        alt=""
        className="dropdown-img"
      />
      <p className="profile-value overflow-ellipsiis px-10">
        {userProfileInfo?.firstName}{" "}{userProfileInfo?.lastName}
      </p>
      {!isWeb3 && <Text block className="estimate-text text-primary manage-ac" onClick={() => { window.open(`${process.env.REACT_APP_PROFILE_URL}/profile`) }}>
        Manage Account
      </Text>}
      {isWeb3 &&
        <div>
          <div className="text-primary d-flex align-items-center w-fit-auto">
            {web3User?.address && <CopyComponent text={web3User?.address} />}
          </div>
          <Network isOpen={isProfileDropdownVisible} />
        </div>
      }
      <ul className="drpdwn-list">
        {!isWeb3 && <LogoutApp />}
        {isWeb3 && <LogoutWeb3App />}
      </ul>
    </div>
  );
};
const AppHeader = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isWeb3] = useState(process.env.REACT_APP_WALLET_TYPE === "non_custodial");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);
  const [triggerLogin, setTriggerLogin] = useState(false)
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedMenuList = getSelectedMenu(window.location.pathname);
    setSelectedMenu(selectedMenuList);
  }, [window.location.pathname]);

  const readNotifications = () => {
    let currentDate = new Date().toISOString()
    let obj = {
      isRead: true,
      readDate: currentDate
    };
    let appName = WalletType === 'non_custodial' ? "Web3payments" : "payments";
    dispatch(setNotificationCount(0));
    readNotification(props?.userConfig?.id, appName, obj).then(() => {
      dispatch(setNotificationCount(0));
    });
    apicalls.encryptValue("true", props?.userConfig?.sk);
  };
  const handleNotificationsDrawer = () => {
    if (props.userConfig?.id) {
      setShowNotificationsDrawer(true)
      return;
    }
    setTriggerLogin(true)
  }
  const closeNotificationDrawer = () => {
    setShowNotificationsDrawer(false)
  }

  const showToggle = () => {
    setCollapsed(!collapsed);
  };

  const getSelectedMenu = (path) => {
    if (path?.includes('dashboard')) {
      return 'dashboard';
    } else if (path?.includes('wallets')) {
      return 'wallets';
    } else if (path?.includes('transactions')) {
      return 'transactions';
    }
    else if (path?.includes('payees')) {
      return 'payees';
    } else if (path?.includes('payins')) {
      return 'payins';
    } else if (path?.includes('/batchpayouts')) {
      return 'batchpayouts';
    } else if (path?.includes('/payout')) {
      return 'payout';
    }
    else if (path?.includes('/vaults')) {
      return 'vaults'
    }
    return '';
  };
  const showNavbar = useMemo(() => {
    return isWeb3 || (
      props.userConfig?.isEmailVerified &&
      props.userConfig?.isCustomerUpdated &&
      props.userConfig?.isPhoneNumberVerified &&
      props.userConfig?.isKYC &&
      checkCustomerState(props.userConfig)
    );
  }, [props.userConfig]);
  const handleMenuClick = (menu) => {
    if (!checkCustomerState(props.userConfig) && !isWeb3) {
      props.history.push("/sumsub");
    }
    else if (!props.userConfig?.isEmailVerified && !isWeb3) {
      props.history.push("/emailVerification");
    } else if (!props.userConfig?.isCustomerUpdated && !isWeb3) {
      props.history.push("/auth0");
    }
    else if (!props.userConfig?.isPhoneNumberVerified && !isWeb3) {
      props.history.push("/phoneVerification");
    }

    else if (!props.userConfig?.isKYC && !isWeb3) {
      props.history.push("/notkyc");
    } else if (!props.userConfig?.id && isWeb3) {
      setTriggerLogin(true)
      props.history.replace("/welcome");
    } else if (window.location?.pathname?.includes('/' + menu)) {
      return
    } else {
      props.history.push('/' + menu)
    }
  };
  const redirection = (prop) => {
    if (prop === 'cards')
      window.open(process.env.REACT_APP_CARDS_URL);
    else if (prop === 'bank')
      window.open(process.env.REACT_APP_BANK_URL);
    else if (prop === 'exchange')
      window.open(process.env.REACT_APP_EXCHANGE_URL);
    else if (prop === 'wallets')
      window.open(process.env.REACT_APP_WALLETS_URL);
  }
  const redirectionDropdown = () => {
    return (
      <div className="wallet-item c-pointer">
        <Title className="super-title">Apps</Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} onClick={() => redirection('exchange')}>
            <div className="c-pointer wallet-menu">
              <span className="icon exchange"></span>
              <div>
                <Title className="supermenu-title">Exchange</Title>
                <p className="text-description">The Crypto App revolutionizes digital asset trading with a secure and user-friendly platform.</p>
              </div>
            </div></Col>
          <Col xs={24} sm={24} md={8} >
            <div className="c-pointer wallet-menu" onClick={() => redirection('bank')}>
              <span className="icon bank"></span>
              <div>
                <Title className="supermenu-title">Bank</Title>
                <p className="text-description">Digital banks rely solely on mobile apps and websites, eliminating the need for physical branches or in-person interaction.</p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} >
            <div className="c-pointer wallet-menu" onClick={() => redirection('cards')}>
              <span className="icon cards"></span>
              <div>
                <Title className="supermenu-title">Cards</Title>
                <p className="text-description">Cards, like debit cards, offer individuals a secure & convenient way to make purchases & access funds.</p>
              </div>
            </div></Col>
          <Col xs={24} sm={24} md={8} >
            <div className="c-pointer wallet-menu" onClick={() => redirection('wallets')}>
              <span className="icon wallet-icon"></span>
              <div>
                <Title className="supermenu-title">Wallet</Title>
                <p className="text-description">Wallets is a secure & convenient digital tool for storing, managing & transacting with crypto using encryption technique</p>
              </div>
            </div></Col>
        </Row>
      </div>
    );
  };
  return (
    <Layout className="layout sticky-header">
      <div className='main-container'>
        <div className="custom-header" id="area">
          <img src={"https://devdottstoragespace.blob.core.windows.net/neoimages/nbsimple_white_logo.svg"} alt="" className="customlogo" />
          {showNavbar && (
            <ul className='menu-item main-menu mobile-dt-header'>
              <li className={`list-item nav c-pointer ${selectedMenu === 'dashboard' ? 'active' : ''}`} onClick={() => handleMenuClick('dashboard')}>
                <span>
                  Dashboard
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'vaults' ? 'active' : ''}`} onClick={() => handleMenuClick('vaults')}>
                <span>
                  Vaults
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'payins' ? 'active' : ''}  `} onClick={() => handleMenuClick('payins')}>
                <span>
                  Pay-In
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'payout' ? 'active' : ''}  `} onClick={() => handleMenuClick('payout/fiat')}>
                <span>
                  Pay-Out
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'batchpayouts' ? 'active' : ''}  `} onClick={() => handleMenuClick('batchpayouts')}>
                <span>
                  Batch Pay-Outs
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'transactions' ? 'active' : ''}`} onClick={() => handleMenuClick('transactions')}>
                <span>
                  Transactions
                </span>
              </li>
              <li className={`list-item nav c-pointer ${selectedMenu === 'payees' ? 'active' : ''}`} onClick={() => handleMenuClick('payees/fiat')}>
                <span>
                  Payees
                </span>
              </li>
            </ul>
          )}
          {<Menu inlineCollapsed={false} theme="light" mode="horizontal" className="header-right mobile-headerview">
            {!isWeb3 && <Menu.Item>
              <Dropdown
                className="mr-12 c-pointer"
                overlayClassName="secureDropdown super-menu"
                overlay={redirectionDropdown}
                placement="bottomRight"
                arrow
              >
                <span className="icon lg menu"></span>
              </Dropdown></Menu.Item>}
            {showNavbar && <Menu.Item key='3' className='rightmenu-icons' onClick={() => handleNotificationsDrawer()}>
              <div className='head-menutogle'>
                <span className='icon lg notification c-pointer' onClick={readNotifications}>
                  {/* {props?.notificationCount != null && */}
                </span>
                {(props.notificationCount !== 0 && props.notificationCount !== null) && (
                  <span className='count-note'>{props.notificationCount}</span>
                )}
              </div>
            </Menu.Item>}
            {props.userConfig?.id && <Menu.Item key="6" className="list-item" >
              <Dropdown className="mr-12 c-pointer" overlayClassName="secureDropdown" overlay={<FilterDropdown isProfileDropdownVisible={isProfileDropdownVisible} />} placement="bottomRight" arrow trigger={'click'}
                onVisibleChange={(value) => setIsProfileDropdownVisible(value)}>
                <div className="profile-dropdown">
                  <img src={props.userConfig?.imageURL ? props.userConfig?.imageURL : DefaultUser} alt="" className="login-user-img" />
                </div>
              </Dropdown>

            </Menu.Item>}
            {!props.userConfig?.id && isWeb3 && <Menu.Item className="list-item">
              <ConnectWeb3 buttonClassName={"head-login-btn"} triggerLogin={triggerLogin} setTriggerLogin={setTriggerLogin} />
            </Menu.Item>}
            <Menu.Item key='5' className='list-item visible-mobile' onClick={showToggle}>
              <span className="icon lg hamburg-icon " />
            </Menu.Item>
          </Menu>}
          {showNotificationsDrawer && (
            <Notifications
              showDrawer={showNotificationsDrawer}
              onClose={() => closeNotificationDrawer()}
            />
          )}
        </div>
        {collapsed && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            className={` ${collapsed ? "sideropen" : ""}`}
            style={{ maxWidth: 200, minWidth: 200, width: 200 }}
          >
            <div className="mobile-header" id="area">
              {showNavbar && <ul className='menu-item main-menu mobile-dt-header'>
                <li className={`list-item  ${selectedMenu === 'dashboard' ? 'active' : ''}`} onClick={() => handleMenuClick('dashboard')}>
                  <span className={`${selectedMenu === 'dashboard' ? 'text-link' : ''}`}>
                    Dashboard
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'vaults' ? 'active' : ''}`} onClick={() => handleMenuClick('vaults')}>
                  <span className={`${selectedMenu === 'vaults' ? 'text-link' : ''}`}>
                    Vaults
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'payins' ? 'active' : ''}  `} onClick={() => handleMenuClick('payins')}>
                  <span className={`${selectedMenu === 'payins' ? 'text-link' : ''}`}>
                    Pay-In
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'payouts' ? 'active' : ''}`} onClick={() => handleMenuClick('payouts')}>
                  <span className={`${selectedMenu === 'payouts' ? 'text-link' : ''}`}>
                    Pay-Out
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'batchpayouts' ? 'active' : ''}`} onClick={() => handleMenuClick('batchpayouts')}>
                  <span className={`${selectedMenu === 'batchpayouts' ? 'text-link' : ''}`}>
                    Batch Pay-Outs
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'transactions' ? 'active' : ''}`} onClick={() => handleMenuClick('transactions')}>
                  <span className={`${selectedMenu === 'transactions' ? 'text-link' : ''}`}>
                    Transactions
                  </span>
                </li>
                <li className={`list-item  ${selectedMenu === 'payees' ? 'active' : ''}`} onClick={() => handleMenuClick('payees/fiat')}>
                  <span className={`${selectedMenu === 'payees' ? 'text-link' : ''}`}>
                    Payees
                  </span>
                </li>
              </ul>}
            </div>
          </Sider>
        )}
      </div>
    </Layout>
  );
};
const connectStateToProps = ({ userConfig, dashboardReducer }) => {
  return { userConfig: userConfig.userProfileInfo, notificationCount: dashboardReducer.notificationCount, web3User: userConfig.web3User };
};
export default connect(
  connectStateToProps,
)(withRouter(AppHeader));